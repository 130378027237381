<template>
    <div class="group-open-loan-table">
        <!-- Main content -->
        <section class="open-loans">
        <!-- Default box -->
            <div class="card card-outline card-info row">
                <div class="card-body">
                    <table id="openLoans" class="table table-striped">
                    <thead class="table-custom-header">
                    <tr>
                        <th>View</th>
                        <th>Released</th>
                        <th>Name</th>
                        <th>Loan#</th>
                        <th>Principal</th>
                        <th>Interest%</th>
                        <th>Due</th>
                        <th>Paid</th>
                        <th>Balance</th>
                        <th>Last Payment</th>
                        <th>Status</th>
                    </tr>
                    </thead>
                    <tbody>
                         <tr v-for="item in items">
                            <td>
                                <router-link :to="{name: 'view-loan-details', params: { id: item.id,bid: item.borrower_id }}" class="btn btn-default btn-sm"><i class="fa fa-edit"></i></router-link>
                                <button class="btn btn-default btn-sm"><i class="fa fa-comment"></i></button>
                            </td>
                            <td>{{item.loan_released_date}}</td>
                            <td><b>{{item.borrower_name}}</b></td>
                            <td><a href="#">1000{{item.id}}</a></td>
                            <td>{{currency(item.principal_amount)}}</td>
                            <td>{{item.default_loan_interest}}</td>
                            <td>{{currency(item.due_amount)}}</td>
                            <td>{{currency(item.total_paid)}}</td>
                            <td>{{currency(item.latest_balance_amount)}}</td>
                            <td>{{item.latest_payment_date}} / {{currency(item.total_paid)}}</td>
                            <td><button class="btn btn-xs btn-primary">Current</button></td>
                        </tr>
                    </tbody>
                    </table>
                </div>
            </div>
        <!-- /.card -->
        </section>
        <!-- /.content -->
    
    </div>
    </template>
    
    <script>
    export default {
       data() {
            return {
                items:[]
            }
        },
    
        created(){
            this.borrowerGroupList()
            this.items
            this.dt = $('#openLoans').DataTable();
        },
        methods: {
            async borrowerGroupList(){
                let response = await axios.get('api/borrowerGroup/' + this.$route.params.id + '/openloans')
                this.items = response.data
            },
        },
        watch: {
            items(val) {
                this.dt.destroy();
                this.$nextTick(()=> {
                    this.dt = $('#openLoans').DataTable()
                });
            }
          },
    }
    </script>