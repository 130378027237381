<template>
    <div class="group-schedule-table">
        <!-- Main content -->
        <section class="schedule">
        <!-- Default box -->
        <div class="card card-outline mt-3 card-info">
            <div class="card-body">
                <table id="example1" class="table table-striped">
                <thead class="table-custom-header">
                <tr>
                    <th>#</th>
                    <th>Borrower</th>
                    <th>Date</th>
                    <th>Description</th>
                    <th>Principal</th>
                    <th>Interest</th>
                    <th>Fees</th>
                    <th>Penalty</th>
                    <th>Due</th>
                    <th>Paid</th>
                    <th>Pending Due</th>
                    <th>Total Due</th>
                    <th>Principal Due</th>
                    <th>Principal Balance</th>
                </tr>
                </thead>
                <tbody>
                    <!-- Main TR-->
                    <tr v-for="(item, index) in items" :key="item.id" :class="classObject(item)">
                        <td>
                            <template v-if="item.category_type == 'Payment'">
                            </template>
                             <template v-if="item.category_type == 'Loan Released'">
                            </template>
                            <template v-if="item.category_type == 'Repayment' || item.category_type == 'Maturity' || item.category_type == 'Paid'">
                                    {{index}}
                            </template>
                        </td>
                        <td>{{item.borrower_name | capitalize}} <br/> <a href="#">1000{{item.loan_id}}</a></td>
                        <td>
                             <template v-if="item.category_type == 'Payment'">
                                <b>{{item.date}}</b>
                            </template>
                            <template v-if="item.category_type == 'Loan Released'">
                                    <b>{{item.date}}</b>
                            </template>
                            <template v-if="item.category_type == 'Repayment'">
                                    {{item.date}}
                            </template>
                            <template v-if="item.category_type == 'Maturity'">
                                    {{item.date}}
                            </template>
                            <template v-if="item.category_type == 'Paid'">
                                    {{item.date}}
                            </template>
                        </td>
                        <td>
                            <template v-if="item.category_type == 'Payment'">
                                <b>{{item.description}}</b>
                            </template>
                             <template v-if="item.category_type == 'Loan Released'">
                                 <b> {{item.description}}</b>
                            </template>
                            <template v-if="item.category_type == 'Repayment'">
                                 {{item.description}}
                            </template>
                            <template v-if="item.category_type == 'Maturity'">
                                    {{item.description}}
                            </template>
                            <template v-if="item.category_type == 'Paid'">
                                    {{item.description}}
                            </template>
                        </td>
                        <td style="text-align:right">
                            <template v-if="item.category_type == 'Payment'">
                                <b>{{currency(item.principal)}}</b>
                            </template>
                             <template v-if="item.category_type == 'Loan Released'">
                                
                            </template>
                            <template v-if="item.category_type == 'Repayment'">
                                 {{currency(item.principal)}}
                            </template>
                            <template v-if="item.category_type == 'Maturity'">
                                    {{currency(item.principal)}}
                            </template>
                            <template v-if="item.category_type == 'Paid'">
                                    {{currency(item.principal)}}
                            </template>
                        </td>
                        <td style="text-align:right">
                             <template v-if="item.category_type == 'Payment'">
                                <b>{{currency(item.interest)}}</b>
                            </template>
                             <template v-if="item.category_type == 'Loan Released'">
                                
                            </template>
                              <template v-if="item.category_type == 'Repayment'">
                                   {{currency(item.interest)}}
                            </template>
                             <template v-if="item.category_type == 'Maturity'">
                                      {{currency(item.interest)}}
                            </template>
                            <template v-if="item.category_type == 'Paid'">
                                    {{currency(item.interest)}}
                            </template>
                        </td>
                        <td style="text-align:right">
                             <template v-if="item.category_type == 'Payment'">
                                <b>{{currency(item.fees)}}</b>
                            </template>
                             <template v-if="item.category_type == 'Loan Released'">
                                
                            </template>
                              <template v-if="item.category_type == 'Repayment'">
                                    {{currency(item.fees)}}
                            </template>
                            <template v-if="item.category_type == 'Maturity'">
                                    {{currency(item.fees)}}
                            </template>
                            <template v-if="item.category_type == 'Paid'">
                                    {{currency(item.fees)}}
                            </template>
                        </td>
                        <td style="text-align:right">
                             <template v-if="item.category_type == 'Payment'">
                                <b>{{currency(item.penalty)}}</b>
                            </template>
                             <template v-if="item.category_type == 'Loan Released'">
                                
                            </template>
                              <template v-if="item.category_type == 'Repayment'">
                                 {{currency(item.penalty)}}
                            </template>
                             <template v-if="item.category_type == 'Maturity'">
                                    {{currency(item.penalty)}}
                            </template>
                            <template v-if="item.category_type == 'Paid'">
                                    {{currency(item.penalty)}}
                            </template>
                        </td>
                        <td style="text-align:right">
                             <template v-if="item.category_type == 'Payment'">
                              
                            </template>
                             <template v-if="item.category_type == 'Loan Released'">
                                
                            </template>
                             <template v-if="item.category_type == 'Repayment'">
                                    {{currency(item.due)}}
                            </template>
                            <template v-if="item.category_type == 'Maturity'">
                                    {{currency(item.due)}}
                            </template>
                            <template v-if="item.category_type == 'Paid'">
                                    {{currency(item.due)}}
                            </template>
                        </td>
                        <td style="text-align:right">
                            <template v-if="item.category_type == 'Payment'">
                                <b>{{currency(item.paid)}}</b>
                            </template>
                             <template v-if="item.category_type == 'Loan Released'">
                                
                            </template>
                            <template v-if="item.category_type == 'Repayment'">
                                    {{currency(item.paid)}}
                            </template>
                             <template v-if="item.category_type == 'Maturity'">
                                    {{currency(item.paid)}}
                            </template>
                            <template v-if="item.category_type == 'Paid'">
                                    {{currency(item.paid)}}
                            </template>
                        </td>
                        <td style="text-align:right">
                             <template v-if="item.category_type == 'Payment'">
                                
                            </template>
                             <template v-if="item.category_type == 'Loan Released'">
                                
                            </template>
                              <template v-if="item.category_type == 'Repayment'">
                                    {{currency(item.pending_due)}}
                            </template>
                            <template v-if="item.category_type == 'Maturity'">
                                    {{currency(item.pending_due)}}
                            </template>
                            <template v-if="item.category_type == 'Paid'">
                                    {{currency(item.pending_due)}}
                            </template>
                        </td>
                        <td style="text-align:right">
                            <template v-if="item.category_type == 'Payment'">
                        
                            </template>
                             <template v-if="item.category_type == 'Loan Released'">
                                
                            </template>
                             <template v-if="item.category_type == 'Repayment'">
                                    {{currency(item.total_due)}}
                            </template>
                            <template v-if="item.category_type == 'Maturity'">
                                    {{currency(item.total_due)}}
                            </template>
                            <template v-if="item.category_type == 'Paid'">
                                    {{currency(item.total_due)}}
                            </template>
                        </td>
                        <td style="text-align:right">
                            <template v-if="item.category_type == 'Payment'">
                        
                            </template>
                             <template v-if="item.category_type == 'Loan Released'">
                            </template>
                             <template v-if="item.category_type == 'Repayment'">
                                    {{currency(item.principal_due)}}
                            </template>
                            <template v-if="item.category_type == 'Maturity'">
                                    {{currency(item.principal_due)}}
                            </template>
                            <template v-if="item.category_type == 'Paid'">
                                    {{currency(item.principal_due)}}
                            </template>
                        </td>
                        <td style="text-align:right">
                             <template v-if="item.category_type == 'Loan Released'">
                                    {{currency(item.principal_balance)}}
                            </template>
                             <template v-if="item.category_type == 'Payment'">
                        
                            </template>
                              <template v-if="item.category_type == 'Repayment'">
                                    {{currency(item.principal_balance)}}
                            </template>
                            <template v-if="item.category_type == 'Maturity'">
                                    {{currency(item.principal_balance)}}
                            </template>
                            <template v-if="item.category_type == 'Paid'">
                                    {{currency(item.principal_balance)}}
                            </template>
                        </td>
                     </tr>
                    
                    <!-- End TR-->
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td><b>Total Due</b></td>
                        <td style="text-align:right">{{currency(TotalPrincipal)}}</td>
                        <td style="text-align:right">{{currency(TotalInterest)}}</td>
                        <td style="text-align:right">{{currency(TotalFees)}}</td>
                        <td style="text-align:right">{{currency(TotalPinalty)}}</td>
                        <td style="text-align:right"><b>{{currency(TotalDue)}}</b></td>
                        <td style="text-align:right"><b>{{currency(TotalPaid)}}</b></td>
                        <td style="text-align:right"><b>{{currency(TotalDue)}}</b></td>
                        <td style="text-align:right"></td>
                        <td style="text-align:right"><b>{{currency(TotalPrincipal)}}</b></td>
                        <td></td>
                    </tr>
                     <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td><b>Total Paid</b></td>
                        <td style="text-align:right">{{currency(TotalPaidPrincipal)}}</td>
                        <td style="text-align:right">{{currency(TotalPaidInterest)}}</td>
                        <td style="text-align:right">{{currency(TotalPaidFees)}}</td>
                        <td style="text-align:right">{{currency(TotalPaidPenalty)}}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                     <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td><b>Total Pending Due</b></td>
                        <td style="text-align:right">{{currency(TotalPrincipal)}}</td>
                        <td style="text-align:right">{{currency(TotalInterest)}}</td>
                        <td style="text-align:right">{{currency(TotalFees)}}</td>
                        <td style="text-align:right">{{currency(TotalPinalty)}}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
                </table>
            </div>
        </div>
        <!-- <p>No schedule found for open loans in this group</p> -->
        <!-- /.card -->
        </section>
        <!-- /.content -->
    
    </div>
    </template>
    
    <script>
    export default {
       data() {
            return {
                items:[]
            }
        },
    
        created(){
            this.groupScheduleList()
        },
        methods: {
            async groupScheduleList(){
                let response = await axios.get('api/loanSchedules/' + this.$route.params.id + '/' + this.$route.params.bid)
                this.items = response.data
                console.log('response =>',JSON.stringify(this.items))
            },
        },
        computed: {
          classObject: function() {
            return (item) => {
               if(item.category_type == 'Payment'){
                   return 'paymentHighLights'
               }

               if(item.category_type == 'Repayment'){
                  return 'repaymentHighLights'
               }

               if(item.category_type == 'Maturity'){
                  return 'maturityHighLights'
               }

               if(item.category_type == 'Paid'){
                  return 'paidHighLights'
               }
            }
          },

          sortedAndReindexedItems(){
             // Define the custom order for statuses
                const statusOrder = { 'active': 1, 'pending': 2, 'inactive': 3 };
      
                        // Sort items based on the defined status order
                        const sortedItems = this.items.slice().sort((a, b) => {
                        return statusOrder[a.status] - statusOrder[b.status];
                });
      
                    // Return the sorted items with reindexing
                  return sortedItems;
          },
         
          TotalPrincipal: function() {
                let sum = 0;
                this.items.forEach(function(item) {
                  sum += parseFloat(item.principal);
                });
              return sum;
            },

          TotalInterest: function() {
            let sum = 0;
            this.items.forEach(function(item) {
                sum += parseFloat(item.interest);
            });
            return sum;
          },
          TotalFees: function() {
            let sum = 0;
            this.items.forEach(function(item) {
                sum += parseFloat(item.fees);
            });
            return sum;
          },
          TotalPinalty: function() {
            let sum = 0;
            this.items.forEach(function(item) {
                sum += parseFloat(item.penalty);
            });
            return sum;
          },
          TotalDue: function() {
            let sum = 0;
            this.items.forEach(function(item) {
                sum += parseFloat(item.due);
            });
            return sum;
          },
          TotalPaid: function() {
            let sum = 0;
            this.items.forEach(function(item) {
                sum += parseFloat(item.paid);
            });
            return sum;
          },
          TotalPendingDue: function() {
            let sum = 0;
            this.items.forEach(function(item) {
                sum += parseFloat(item.pending_due);
            });
            return sum;
          },
          TotalPrincipalDue: function() {
            let sum = 0;
            this.items.forEach(function(item) {
                sum += parseFloat(item.principal_due);
            });
            return sum;
          },

          //TOTAL PAID
         
          TotalPaidPrincipal: function() {
            let sum = 0;
            this.items.forEach(function(item) {
                if(item.category_type == 'Payment'){
                    sum += parseFloat(item.principal);
                }
                
            });
            return sum;
          },
           TotalPaidInterest: function() {
            let sum = 0;
            this.items.forEach(function(item) {
                if(item.category_type == 'Payment'){
                    sum += parseFloat(item.interest);
                }
            });
            return sum;
           },
           TotalPaidFees: function() {
             let sum = 0;
             this.items.forEach(function(item) {
                if(item.category_type == 'Payment'){
                    sum += parseFloat(item.fees);
                }
             });
            return sum;
           },
          TotalPaidPenalty: function() {
            let sum = 0;
            this.items.forEach(function(item) {
                if(item.category_type == 'Payment'){
                    sum += parseFloat(item.penalty);
                }
            });
            return sum;
          },
        }
    }
 </script>
<style>
  .highlight {
        background-color: pink !important; /* Change to the desired highlight color */
  }

  .paymentHighLights{
    background-color: #b5bbc8 !important;
  }
  .repaymentHighLights{
    background-color: rgb(255, 255, 255) !important;
  }
  .maturityHighLights{
    background-color: pink !important;
  }
  .paidHighLights{
      background-color: #dff0d8 !important;
  }
</style>