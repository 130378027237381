/* ------------ Global Components -------------- */
import AppNavBar from '../components/partials/AppNavBar'
import AppSideBar from '../components/partials/AppSideBar'
import AppFooter from '../components/partials/AppFooter'

import groupCommentsTable from '../components/group-comments-table'
import groupOpenLoanTable from '../components/group-open-loan-table'
import groupPendingDuesTable from '../components/group-pending-dues-table'
import groupRepaymentTable from '../components/group-repayment-table'
import groupSavingsTable from '../components/group-savings-table'
import groupScheduleTable from '../components/group-schedule-table'
import loanRepaymentsTable from '../components/loan-repayments-table'
import loanTerms from '../components/loan-terms'
import loanScheduleTable from '../components/loan-schedule-table'
import loanPendingDuesTable from '../components/loan-pending-dues-table'
import loanPenaltySettings from '../components/loan-penalty-settings'
import loanCollateral from '../components/loan-collateral'
import loanExpenses from '../components/loan-expenses'
import loanOtherIncome from '../components/loan-other-income'
import loanFiles from '../components/loan-files'
import loanComments from '../components/loan-comments'

function setupComponents(Vue){
    Vue.component('AppNavBar', AppNavBar)
    Vue.component('AppSideBar', AppSideBar)
    Vue.component('AppFooter', AppFooter)
    Vue.component('groupCommentsTable', groupCommentsTable)
    Vue.component('groupOpenLoanTable', groupOpenLoanTable)
    Vue.component('groupPendingDuesTable', groupPendingDuesTable)
    Vue.component('groupRepaymentTable', groupRepaymentTable)
    Vue.component('groupSavingsTable', groupSavingsTable)
    Vue.component('groupScheduleTable', groupScheduleTable)
    Vue.component('loanRepaymentsTable', loanRepaymentsTable)
    Vue.component('loanTerms', loanTerms)
    Vue.component('loanScheduleTable', loanScheduleTable)
    Vue.component('loanPendingDuesTable', loanPendingDuesTable)
    Vue.component('loanPenaltySettings', loanPenaltySettings)
    Vue.component('loanCollateral', loanCollateral)
    Vue.component('loanExpenses', loanExpenses)
    Vue.component('loanOtherIncome', loanOtherIncome)
    Vue.component('loanFiles', loanFiles)
    Vue.component('loanComments', loanComments)
}

export {
    setupComponents
  }
  