<template>
    <div class="group-pending-dues-table">
        <!-- Main content -->
        <section class="peding-dues">
        <!-- Default box -->
        <div class="card card-outline mt-3 card-info">
            <div class="card-body">
                <table id="example1" class="table table-striped">
                <!-- <thead class="table-custom-header">
                    <tr>
                        <th width="400">Based on Loan Terms:</th>
                        <th style="text-align:right" width="200">Principal</th>
                        <th style="text-align:right" width="200">Interest</th>
                        <th style="text-align:right" width="200">Fees</th>
                        <th style="text-align:right" width="200">Penalty</th>
                        <th style="text-align:right" width="200">Total</th>
                    </tr>
                </thead> -->
                <tbody>
                    <tr>
                        <th width="400">Based on Loan Terms:</th>
                        <th style="text-align:right" width="200">Principal</th>
                        <th style="text-align:right" width="200">Interest</th>
                        <th style="text-align:right" width="200">Fees</th>
                        <th style="text-align:right" width="200">Penalty</th>
                        <th style="text-align:right" width="200">Total</th>
                    </tr>
                    <tr>
                        <td><button class="btn btn-danger btn-sm w-100 text-left">Total Due </button></td>
                        <td style="text-align:right">{{ formatNumber(principal_amount) }}</td>
                        <td style="text-align:right">{{ formatNumber(interest_amount) }}</td>
                        <td style="text-align:right">0</td>
                        <td style="text-align:right">0</td>
                        <td style="text-align:right"><strong>{{ formatNumber(total_loan_term_due) }}</strong></td>
                    </tr>
                    <tr>
                        <td><button class="btn btn-success btn-sm w-100 text-left">Total Paid </button></td>
                        <td style="text-align:right">{{ formatNumber(principal_paid) }}</td>
                        <td style="text-align:right">{{ formatNumber(interest_paid) }}</td>
                        <td style="text-align:right">0</td>
                        <td style="text-align:right">0</td>
                        <td style="text-align:right"><strong>{{ formatNumber(total_loan_term_paid) }}</strong></td>
                    </tr>
                     <tr>
                        <td><button class="btn btn-dark btn-sm w-100 text-left">Balance </button></td>
                        <td style="text-align:right">{{ formatNumber(balance_principal) }}</td>
                        <td style="text-align:right">{{ formatNumber(balance_interest) }}</td>
                        <td style="text-align:right">0</td>
                        <td style="text-align:right">0</td>
                        <td style="text-align:right"><strong>{{ formatNumber(total_loan_term_balance) }}</strong></td>
                    </tr>
                    <tr>
                        <th width="400">Based on Loan Schedule:</th>
                        <th width="200"></th>
                        <th width="200"></th>
                        <th width="200"></th>
                        <th width="200"></th>
                        <th width="200"></th>
                    </tr>
                    <tr>
                        <td><button class="btn btn-danger btn-sm w-100 text-left">Due till {{ formatDate(due_till) }}  </button></td>
                        <td style="text-align:right">{{ formatNumber(sum_principal) }}</td>
                        <td style="text-align:right">{{ formatNumber(sum_interest) }}</td>
                        <td style="text-align:right">0</td>
                        <td style="text-align:right">0</td>
                        <td style="text-align:right"><strong>{{ formatNumber(total_due_till) }}</strong></td>
                    </tr>
                    <tr>
                        <td><button class="btn btn-success btn-sm w-100 text-left">Paid till {{ formatDate(paid_till) }}  </button></td>
                        <td style="text-align:right">{{ formatNumber(sum_principal_paid) }}</td>
                        <td style="text-align:right">{{ formatNumber(sum_interest_paid) }}</td>
                        <td style="text-align:right">0</td>
                        <td style="text-align:right">0</td>
                        <td style="text-align:right"><strong>{{ formatNumber(total_paid_till) }}</strong></td>
                    </tr>
                     <tr>
                        <td><button class="btn btn-dark btn-sm w-100 text-left">Balance till {{ formatDate(balance_till) }}  </button></td>
                        <td style="text-align:right">{{ formatNumber(sum_balance_principal) }}</td>
                        <td style="text-align:right">{{ formatNumber(sum_balance_interest) }}</td>
                        <td style="text-align:right">0</td>
                        <td style="text-align:right">0</td>
                        <td style="text-align:right"><strong>{{ formatNumber(total_balance_till) }}</strong></td>
                    </tr>
                    <!-- <tr>
                        <th width="400">Pro-Rata Basis::</th>
                        <th width="200"></th>
                        <th width="200"></th>
                        <th width="200"></th>
                        <th width="200"></th>
                        <th width="200"></th>
                    </tr>
                    <tr>
                        <td>
                        <div class="input-group input-group-sm col-md-6">
                          <input type="date" class="form-control">
                          <span class="input-group-append">
                            <button type="button" class="btn btn-info btn-flat">Go!</button>
                           </span>
                        </div>
                        </td>
                        <td style="text-align:right"></td>
                        <td style="text-align:right"></td>
                        <td style="text-align:right"></td>
                        <td style="text-align:right"></td>
                        <td style="text-align:right"></td>
                    </tr>
                    
                    <tr>
                        <td><button class="btn btn-danger btn-sm w-50">Due till 06/06/2024  </button></td>
                        <td style="text-align:right">0</td>
                        <td style="text-align:right">0</td>
                        <td style="text-align:right">0</td>
                        <td style="text-align:right">0</td>
                        <td style="text-align:right">0</td>
                    </tr>
                    <tr>
                        
                        <td><button class="btn btn-success btn-sm w-50">Paid till 06/06/2024  </button></td>
                        <td style="text-align:right">0</td>
                        <td style="text-align:right">0</td>
                        <td style="text-align:right">0</td>
                        <td style="text-align:right">0</td>
                        <td style="text-align:right">0</td>
                    </tr>
                     <tr>
                        <td><button class="btn btn-dark btn-sm w-50">Balance till 06/06/2024  </button></td>
                        <td style="text-align:right">0</td>
                        <td style="text-align:right">0</td>
                        <td style="text-align:right">0</td>
                        <td style="text-align:right">0</td>
                        <td style="text-align:right">0</td>
                    </tr> -->
                </tbody>
                </table>
            </div>
        </div>
        <!-- /.card -->
        </section>
        <!-- /.content -->
        <!-- {{ userLoanID }} -->
    </div>
    </template>
    
    <script>
    export default {
        // props: {
        //     userLoanID: Number,
        // },
        
       data() {
            return {
                items:[],
                userLoans: [],
                //loanID: null,
                principal_amount: 0,
                interest_amount: 0,
                total_loan_term_due: 0,
                principal_paid: 0,
                interest_paid: 0,
                total_loan_term_paid: 0,
                balance_principal: 0,
                balance_interest: 0,
                total_loan_term_balance: 0,
                due_till: '',
                paid_till: '',
                balance_till: '',
                sum_principal: 0,
                sum_interest: 0,
                total_due_till: 0,
                sum_principal_paid: 0,
                sum_interest_paid: 0,
                total_paid_till: 0,
                sum_balance_principal: 0, 
                sum_balance_interest: 0,
                total_balance_till: 0
            }
        },

        computed: {
            processedData() {
            // return this.userLoanID ? this.userLoanID : 'No data available';
            return this.$route.params.id ? this.$route.params.id: 'No data available';
            }
        },
    
        created(){
            this.borrowerGroupList();
            // this.fetchUserPendingDuesTerms(this.userLoanID);
            // this.fetchUserPendingDuesSchedule(this.userLoanID);
            
            this.fetchUserPendingDuesTerms(this.$route.params.id);
            this.fetchUserPendingDuesSchedule(this.$route.params.id);
        },

        mounted() {
            //console.log(this.userLoanID);
            //console.log(2);
            //this.fetchUserPendingDues(2);
        },

        methods: {
            async borrowerGroupList(){
                let response = await axios.get('api/borrowerGroup')
                this.items = response.data
            },

            formatNumber(num) {
                return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            },

            formatDate (date) {
                if (!date) return null

                const [year, month, day] = date.split('-')
                return `${month}/${day}/${year}`
            },

            fetchUserPendingDuesTerms(param) {
                axios
                .get('api/loan/pendingDuesLoanTerms/' + param)
                .then(response => {
                    console.log(response.data)
                    this.userLoans = response.data;
                    this.principal_amount = response.data[0].principal_amount;
                    this.interest_amount = response.data[0].interest_amount;
                    this.total_loan_term_due = response.data[0].total_loan_term_due;
                    this.principal_paid = response.data[0].principal_paid;
                    this.interest_paid = response.data[0].interest_paid;
                    this.total_loan_term_paid = response.data[0].total_loan_term_paid;
                    this.balance_principal = response.data[0].balance_principal;
                    this.balance_interest = response.data[0].balance_interest;
                    this.total_loan_term_balance = response.data[0].total_loan_term_balance;
                })
                .catch(error => {
                    console.log(error)
                })
            },

            fetchUserPendingDuesSchedule(param) {
                axios
                .get('api/loan/pendingDuesLoanSchedule/' + param)
                .then(response => {
                    console.log(response.data)
                    this.due_till = response.data[0].due_till;
                    this.paid_till = response.data[0].paid_till;
                    this.balance_till = response.data[0].balance_till;
                    this.sum_principal = response.data[0].sum_principal;
                    this.sum_interest = response.data[0].sum_interest;
                    this.total_due_till = response.data[0].total_due_till;
                    this.sum_principal_paid = response.data[0].sum_principal_paid;
                    this.sum_interest_paid = response.data[0].sum_interest_paid;
                    this.total_paid_till = response.data[0].total_paid_till;
                    this.sum_balance_principal = response.data[0].sum_balance_principal;
                    this.sum_balance_interest = response.data[0].sum_balance_interest;
                    this.total_balance_till = response.data[0].total_balance_till;
                })
                .catch(error => {
                    console.log(error)
                })
            }

            // fetchUserLoans() {

            //     axios
            //     .get('api/loan/pendingDuesLoanTerms/' + this.loanID)
            //     .then(response => {
            //         console.log(response.data)
            //         this.userLoans = response.data;
            //         $('#pendingDuesTabTable').DataTable();
            //     })
            //     .catch(error => {
            //         console.log(error)
            //     })
            // }
        },

        // created() {
        //     this.fetchUserLoans();
        // },

        // mounted() {
        //     console.log(this.userLoanID);
        //     $('.select2').select2()
        //     // this.dt = $('#viewPastMaturityDate').DataTable();
        //     // this.fetchUserLoans();

        //     // axios
        //     // .get('api/loan/pendingDuesLoanTerms/' + this.userLoanID)
        //     // .then(response => {
        //     //     console.log(response.data)
        //     //     this.userLoans = response.data;
        //     //     //this.principal_amount = response.data[0].principal_amount;
        //     //     //console.log(this.principal_amount);
        //     // })
        //     // .catch(error => {
        //     //     console.log(error)
        //     // })
        //     this.fetchUserPendingDues(this.userLoanID);
        // }
    }
    </script>