<template>
  <div>
     <section class="content">
        <!-- Default box -->
        <div class="container-fluid">
             <h1>Loan Files</h1>
        </div>
        <!-- /.card -->
     </section>
    </div>
  </template>
  
  <script>

    export default {
    
      data() {
        return {
        
        }
      },

      created(){
         
      },

      methods: {

      },

      computed: {
      
      }
    }
  </script>