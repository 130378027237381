<template>
        <div class="group-repayment-table">
            <!-- Main content -->
            <section class="group-repayment">
            <!-- Default box -->
                <div class="card card-outline card-info row">
                    <div class="card-body">
                        <table id="groupRepayment" class="table table-striped">
                        <thead class="table-custom-header">
                        <tr>
                            <th>Name</th>
                            <th>Collection Date</th>
                            <th>Collected By</th>
                            <th>Method</th>
                            <th>Amount</th>
                            <th>Action</th>
                            <th>Receipt</th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in items">
                                <td><b>{{item.borrower_name | capitalize}}</b></td>
                                <td>{{item.collection_date}}</td>
                                <td>{{item.collected_by}}</td>
                                <td>{{item.accounting}}</td>
                                <td>{{currency(item.amount_paid)}}</td>
                                <td>
                                    <a href="#">Edit</a> &nbsp;
                                    <a href="#">Delete</a>
                                </td>
                                <td >
                                    <button class="btn btn-sm btn-default"><i class="fa fa-print"></i></button>
                                    <button class="btn btn-sm btn-default"><i class="fa fa-file-pdf"></i></button>
                                    <button class="btn btn-sm btn-default"><i class="fa fa-envelope"></i></button>
                                </td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                </div>
            <!-- /.card -->
            </section>
            <!-- /.content -->
        
        </div>
        </template>
        
        <script>
        export default {
           data() {
                return {
                    items:[]
                }
            },
        
            created(){
                this.groupRepayment()
                this.dt = $('#groupRepayment').DataTable();
            },
            methods: {
              async groupRepayment(){
                let response = await axios.get('api/borrowerGroup/' + this.$route.params.id + '/groupRepayment')
                this.items = response.data
                console.log(JSON.stringify(this.items))
               },
            },
            watch: {
               items(val) {
                this.dt.destroy();
                this.$nextTick(()=> {
                    this.dt = $('#groupRepayment').DataTable()
                });
              }
          },
        }
        </script>